<template>
  <b-row>
    <b-col md="3">
      <b-form-group
        label="Company Group"
      >
        <custom-v-select
          v-model="selectedCompanyGroups"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="companyGroupOptions"
          label="text"
          placeholder="Select Company Groups"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Supplier"
      >
        <custom-v-select
          v-model="selectedSuppliers"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="supplierOptions"
          label="text"
          placeholder="Select Suppliers"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Date Range"
      >
        <flat-picker
          v-model="dateRange"
          placeholder="Select Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Closed On Range"
      >
        <flat-picker
          v-model="closedOnDateRange"
          placeholder="Select Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Status"
      >
        <custom-v-select
          v-model="selectedStatusOption"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusOptions"
          label="text"
          placeholder="Select Option"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="PO Number"
      >
        <b-form-input
          v-model.trim="poNumber"
          type="text"
          placeholder="Search PO Number"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Item Name"
      >
        <b-form-input
          v-model.trim="itemName"
          type="text"
          placeholder="Search Item Name"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    FlatPicker,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    CustomVSelect,
  },
  data() {
    return {
      companyGroupOptions: [],
      selectedCompanyGroups: [],
      supplierOptions: [],
      selectedSuppliers: [],
      dateRange: null,
      closedOnDateRange: null,
      poNumber: null,
      itemName: null,
      statusOptions: [{ text: 'Open', value: 'Open' }, { text: 'Closed', value: 'Closed' }, { text: 'Cancelled', value: 'Cancelled' }],
      selectedStatusOption: null,
    }
  },
  watch: {
    dateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
    closedOnDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/company_group_options')
        .then(res => {
          this.companyGroupOptions = res.data.data
        })

      axios.get('/supplier_options')
        .then(res => {
          this.supplierOptions = res.data.data
        })
    },
    filtersChangeHandler() {
      const filtersdata = {}
      if (this.selectedCompanyGroups.length > 0) {
        filtersdata.company_groups = this.selectedCompanyGroups.map(item => item.id)
      }
      if (this.selectedSuppliers.length > 0) {
        filtersdata.suppliers = this.selectedSuppliers.map(item => item.id)
      }
      if (this.dateRange) {
        const [dateFrom, dateTo] = this.dateRange.split(' to ')
        if (dateFrom && dateTo) {
          filtersdata.date_from = dateFrom
          filtersdata.date_to = dateTo
        }
      }
      if (this.closedOnDateRange) {
        const [closedOnDateFrom, closedOnDateTo] = this.closedOnDateRange.split(' to ')
        if (closedOnDateFrom && closedOnDateTo) {
          filtersdata.closed_on_from = closedOnDateFrom
          filtersdata.closed_on_to = closedOnDateTo
        }
      }
      if (this.selectedStatusOption) {
        filtersdata.status = this.selectedStatusOption.value
      }
      if (this.poNumber) {
        filtersdata.po_number = this.poNumber
      }
      if (this.itemName) {
        filtersdata.item_name = this.itemName
      }
      this.$emit('filtersUpdated', filtersdata)
    },
  },

}
</script>
