<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <add-purchase-order
      v-if="showPOForm"
      @modal-closed="showPOForm = false"
      @po-added="addPOHandler"
    />

    <b-row>
      <b-col
        align="right"
      >
        <b-button
          variant="outline-primary"
          :disabled="submitting || selectedRecords.length === 0"
          class="mb-1"
          @click="updatePoStatus('Closed')"
        >
          Mark As Closed
          <b-spinner
            v-if="submitting"
            label="Small Spinner"
            small
          />
        </b-button>

        <b-button
          variant="outline-primary"
          :disabled="submitting || selectedRecords.length === 0"
          class="mb-1 ml-2"
          @click="updatePoStatus('Cancelled')"
        >
          Mark As Cancelled
          <b-spinner
            v-if="submitting"
            label="Small Spinner"
            small
          />
        </b-button>

        <b-button
          variant="outline-primary"
          class="mb-1 ml-2"
          @click="showPOForm = true"
        >
          Add Purchase Order
        </b-button>

      </b-col>
    </b-row>

    <purchase-order-details
      v-if="openPoId"
      :po-id="openPoId"
      @modal-closed="openPoId=null"
    />

    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      @sort-changed="sortingChanged"
    >
      <template #head(select)>
        <b-form-checkbox
          v-model="allRecordsSeleted"
          :disabled="results.length === 0"
          @change="toggleRecordsSelection"
        />
      </template>

      <template #cell(select)="data">
        <b-form-checkbox
          v-model="selectedRecords"
          :value="data.item.id"
        />
      </template>
      <template #cell(date)="data">
        {{ formatedDate(data.item.date) }}
      </template>

      <template #cell(pdf_file)="data">
        <a
          v-if="data.item.pdf_file"
          :href="data.item.pdf_file"
          target="_blank"
        >
          <feather-icon
            v-b-tooltip.hover="{boundary:'window'}"
            title="View File"
            icon="FileIcon"
            class="cursor-pointer"
            color="green"
          />
        </a>
        <feather-icon
          v-else
          icon="FileIcon"
          color="red"
        />
      </template>

      <template #cell(closed_on)="data">
        <span v-if="data.item.closed_on">
          {{ formatedDate(data.item.closed_on) }}
        </span>
      </template>
      <template #cell(status)="data">
        <status-icon :value="data.item.status" />
      </template>
      <template #cell(total_amount)="data">
        {{ formateNumber(data.item.total_amount) }}
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="View Details"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="displayDetails(data.item.id)"
        />
      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />

  </div>

</template>

<script>
import {
  BSpinner, BTable, BAlert, BFormCheckbox, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import StatusIcon from '../UI/StatusIcon.vue'
import AddPurchaseOrder from './AddPurchaseOrder.vue'
import PurchaseOrderDetails from './PurchaseOrderDetails.vue'
import DetailedPagination from '../UI/DetailedPagination.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BRow,
    BCol,
    BButton,
    BAlert,
    StatusIcon,
    AddPurchaseOrder,
    BFormCheckbox,
    PurchaseOrderDetails,
    DetailedPagination,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,

      results: [],
      tableFields: [
        { key: 'select' },
        { key: 'po_number', label: 'PO NUMBER', sortable: true },
        { key: 'date', label: 'DATE', sortable: true },
        { key: 'company_group', label: 'COMPANY GROUP', sortable: true },
        { key: 'supplier', label: 'SUPPLIER', sortable: true },
        { key: 'pdf_file', label: 'PDF FILE', sortable: true },
        { key: 'total_items', label: 'TOTAL ITEMS', sortable: true },
        {
          key: 'total_amount', label: 'TOTAL AMOUNT', sortable: true, class: 'text-right pr-2',
        },
        { key: 'status', label: 'STATUS', sortable: true },
        { key: 'closed_on', label: 'CLOSED ON', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'po_number',
      sortDesc: true,
      selectedRecords: [],
      allRecordsSeleted: false,
      submitting: false,
      showPOForm: false,
      openPoId: null,
    }
  },
  watch: {
    selectedRecords(newValue) {
      if (this.results.length > 0 && newValue.length === this.results.length) {
        this.allRecordsSeleted = true
      } else {
        this.allRecordsSeleted = false
      }
    },
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    toggleRecordsSelection(checked) {
      this.selectedRecords = checked ? this.results.map(result => result.id) : []
    },
    fetchItems() {
      this.loading = true
      axios.post('/filtered_pos', {
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        filters: { ...this.filters },
      })
        .then(res => {
          this.results = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    updatePoStatus(status) {
      this.submitting = true
      const data = {
        po_ids: this.selectedRecords,
        status,
      }
      axios.post('/update_po_status', data)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.submitting = false
          this.fetchItems()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.error || 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.submitting = false
        })
    },
    addPOHandler() {
      this.showPOForm = false
      this.fetchItems()
    },
    displayDetails(poId) {
      this.openPoId = poId
    },
  },
}
</script>
