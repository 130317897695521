<template>
  <b-modal
    v-model="showModal"
    title="Add Purchase Order"
    size="lg"
    class="m-4"
    no-close-on-backdrop

    @hidden="$emit('modal-closed')"
    @ok="submitHandler"
  >
    <b-form
      @submit.prevent="submitHandler"
    >
      <b-row>
        <b-col cols="12">
          <b-alert
            variant="danger"
            :show="errorMessage ? true : false"
          >
            <div class="alert-body">
              <p>
                {{ errorMessage }}
              </p>
            </div>
          </b-alert>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Company Group"
            label-for="compnayGroup"
          >
            <custom-v-select
              id="compnayGroup"
              v-model="selectedCompanyGroup"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="companyGroupOptions"
              placeholder="Select Company Group"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Supplier"
            label-for="supplier"
          >
            <custom-v-select
              id="Supplier"
              v-model="selectedSupplier"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="supplierOptions"
              placeholder="Select Supplier"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="PDF File"
            label-for="pdf-file"
          >
            <b-form-file
              id="pdf-file"
              v-model="pdfFile"
              accept=".pdf"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="CSV File"
            label-for="csv-file"
          >
            <b-form-file
              id="csv-file"
              v-model="csvFile"
              accept=".csv"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Total Amount"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="totalAmount"
              type="number"
              placeholder="Total Amount"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Total Item"
            label-for="item"
          >
            <b-form-input
              id="item"
              v-model="totalItem"
              type="number"
              placeholder="Total Item"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Status"
            label-for="status"
          >
            <custom-v-select
              id="status"
              v-model="selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              placeholder="Select Status"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <label for="datepicker-full-width">Closed On</label>
          <b-form-datepicker
            id="datepicker-full-width"
            v-model="closedOn"
            menu-class="w-100"
            calendar-width="100%"
            class="mb-2"
          />
        </b-col>
        <b-col cols="12">
          <label for="textarea-default">Remarks</label>
          <b-form-textarea
            id="textarea-default"
            v-model="remarks"
            rows="4"
          />
        </b-col>
      </b-row>

    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving"
        @click="ok()"
      >
        Create
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>

    </template>
  </b-modal>

</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BFormInput, BFormTextarea, BFormDatepicker, BFormFile, BForm, BSpinner, BAlert,
} from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    CustomVSelect,
    BModal,
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    BSpinner,
    BAlert,
  },

  data() {
    return {
      showModal: true,
      selectedCompanyGroup: null,
      companyGroupOptions: [],
      selectedSupplier: null,
      supplierOptions: [],
      pdfFile: null,
      csvFile: null,
      totalAmount: null,
      totalItem: null,
      selectedStatus: { text: 'Open', value: 'Open' },
      statusOptions: [{ text: 'Open', value: 'Open' }, { text: 'Closed', value: 'Closed' }, { text: 'Cancelled', value: 'Cancelled' }],
      closedOn: null,
      remarks: null,
      saving: false,
      errorMessage: null,
    }
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/company_group_options')
        .then(res => {
          this.companyGroupOptions = res.data.data
        })

      axios.get('/supplier_options')
        .then(res => {
          this.supplierOptions = res.data.data
        })
    },
    submitHandler(event) {
      event.preventDefault()
      const formData = new FormData()
      if (this.selectedCompanyGroup) {
        formData.append('company_group', this.selectedCompanyGroup.id)
      }
      if (this.selectedSupplier) {
        formData.append('supplier', this.selectedSupplier.id)
      } else {
        this.errorMessage = 'Supplier field can not be blank'
        return
      }
      if (this.pdfFile) {
        formData.append('pdf_file', this.pdfFile)
      }
      if (this.csvFile) {
        formData.append('csv_file', this.csvFile)
      }
      if (this.totalAmount) {
        formData.append('total_amount', this.totalAmount)
      }
      if (this.totalItem) {
        formData.append('total_items', this.totalItem)
      }
      if (this.selectedStatus) {
        formData.append('status', this.selectedStatus.value)
      }
      if (this.closedOn) {
        formData.append('closed_on', this.closedOn)
      }
      if (this.remarks) {
        formData.append('remarks', this.remarks)
      }
      this.saving = true
      axios.post('/po/', formData)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message || 'Purchase Order Created',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.$emit('po-added')
          this.saving = false
        }).catch(error => {
          this.errorMessage = error?.response?.data || 'Something went wrong'
          this.saving = false
        })
    },
  },
}

</script>
