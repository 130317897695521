<template>
  <b-card title="Purchase Orders">
    <p-o-filters @filtersUpdated="filtersChangeHandler" />
    <purchase-orders-list :filters="filters" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import PurchaseOrdersList from '../../components/PurchaseOrder/PurchaseOrdersList.vue'
import POFilters from '../../components/PurchaseOrder/POFilters.vue'

export default {
  components: {
    BCard,
    PurchaseOrdersList,
    POFilters,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
