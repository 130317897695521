<template>
  <b-modal
    v-model="modalShow"
    size="lg"
    scrollable
    title="Purchase Order Details"
    @hidden="$emit('modal-closed')"
  >

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">
      <b-table-simple
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              PO Number
            </b-th>
            <b-td>
              {{ poDetails.po_number }}
            </b-td>
            <b-th>
              Date
            </b-th>
            <b-td>
              {{ formatedDate(poDetails.date) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Company Gorup
            </b-th>
            <b-td>
              {{ poDetails.company_group__name }}
            </b-td>
            <b-th>
              Supplier
            </b-th>
            <b-td>
              {{ poDetails.supplier__name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              PDF File
            </b-th>
            <b-td>
              <a
                v-if="poDetails.pdf_file"
                :href="poDetails.pdf_file"
                target="_blank"
              >
                <span>View File</span>
              </a>
            </b-td>
            <b-th>
              CSV File
            </b-th>
            <b-td>
              <a
                v-if="poDetails.csv_file"
                :href="poDetails.csv_file"
                target="_blank"
              >
                <span>View File</span>
              </a>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Total Amount
            </b-th>
            <b-td>
              {{ formateNumber(poDetails.total_amount) }}
            </b-td>
            <b-th>
              Total Items
            </b-th>
            <b-td>
              {{ poDetails.total_items }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Status
            </b-th>
            <b-td>
              <status-icon :value="poDetails.status" />
            </b-td>
            <b-th>
              Closed On
            </b-th>
            <b-td>
              {{ poDetails.closed_on }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Created By
            </b-th>
            <b-td colspan="3">
              {{ poDetails.created_by__fullname }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Remarks
            </b-th>
            <b-td colspan="3">
              {{ poDetails.remarks }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          class="float-right"
          @click="modalShow=false"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BAlert, BSpinner, BTableSimple, BTbody, BTd, BTr, BTh,
} from 'bootstrap-vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import StatusIcon from '../UI/StatusIcon.vue'

export default {
  components: {
    BButton, BAlert, BSpinner, BTableSimple, BTbody, BTd, BTr, BTh, StatusIcon,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    poId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      poDetails: {},
      modalShow: true,
      loadingError: null,
      loading: true,
    }
  },
  created() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      axios.get(`/po/${this.poId}`)
        .then(res => {
          this.poDetails = res.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
  },
}
</script>
